import Facebook from '../../../dashboard/routes/dashboard/settings/inbox/channels/Facebook.vue';
import Website from '../../../dashboard/routes/dashboard/settings/inbox/channels/Website.vue';
import Twitter from '../../../dashboard/routes/dashboard/settings/inbox/channels/Twitter.vue';
import Api from '../../../dashboard/routes/dashboard/settings/inbox/channels/Api.vue';
import Email from '../../../dashboard/routes/dashboard/settings/inbox/channels/Email.vue';
import Sms from '../../../dashboard/routes/dashboard/settings/inbox/channels/Sms.vue';
import EmblueWhatsapp from './EmblueWhatsapp.vue';
import Line from '../../../dashboard/routes/dashboard/settings/inbox/channels/Line.vue';
import Telegram from '../../../dashboard/routes/dashboard/settings/inbox/channels/Telegram.vue';

const channelViewList = {
  facebook: Facebook,
  website: Website,
  twitter: Twitter,
  api: Api,
  email: Email,
  sms: Sms,
  whatsapp: EmblueWhatsapp,
  line: Line,
  telegram: Telegram,
};

export default {
  create() {
    return {
      props: {
        channel_name: {
          type: String,
          required: true,
        },
      },
      name: 'new-channel-view',
      render(h) {
        return h(channelViewList[this.channel_name] || null);
      },
    };
  },
};
