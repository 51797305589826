<template>
  <div class="w-16 h-16">
    <router-link :to="dashboardPath" replace>
      <img
        alt="emblue logo"
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmlld0JveD0iMCAwIDUwMCA1MDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDxnDQogICAgZmlsbD0iI2ZmZiINCiAgICB0cmFuc2Zvcm09Im1hdHJpeCgwLjk5OTk5OTk5OTk5OTk5OTksIDAsIDAsIDAuOTk5OTk5OTk5OTk5OTk5OSwgMTcyLjE3NTg3NTM2ODM1NDgsIDExOS40MjExNzQ4NzUyMTcyOCkiDQogID4NCiAgICA8cGF0aA0KICAgICAgZD0iTSAxNzUuMTMyIDEwOS4zNzEgQyAxNzUuMTMyIDU1LjA0NyAxMzEuMTgyIDEwLjgyNCA3Ni41ODUgMTAuODI0IEMgMjEuOTg4IDEwLjgyNCAtMjEuOTYyIDU0Ljc3NCAtMjEuOTYyIDEwOS4zNzEgTCAtMjEuOTYyIDExMC40NjMgTCAtMjEuNjg5IDExMC40NjMgQyAtMjEuOTYyIDExNy44MzQgLTIwLjA1MSAxNjcuMjQ0IDYyLjM5IDI0OC44NjYgQyA3MC4wMzMgMjU2Ljc4MiA4Mi44NjMgMjU2Ljc4MiA5MC41MDcgMjQ5LjEzOSBDIDkwLjUwNyAyNDkuMTM5IDkwLjc4IDI0OC44NjYgOTAuNzggMjQ4Ljg2NiBDIDE3My4yMjEgMTY3LjUxNyAxNzUuMTMyIDExOC4xMDcgMTc0Ljg1OSAxMTAuNDYzIEwgMTc1LjEzMiAxMTAuNDYzIEMgMTc1LjEzMiAxMDkuOTE3IDE3NS4xMzIgMTA5LjY0NCAxNzUuMTMyIDEwOS4zNzEgWiINCiAgICAgIHN0eWxlPSIiDQogICAgLz4NCiAgPC9nPg0KPC9zdmc+DQo="
      />
    </router-link>
  </div>
</template>
<script>
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
  },
};
</script>
